import React, { Component } from "react";
import style from "./FullFeatureList.module.css";
import { getString } from "../../Utils/Internationalization";

class FullFeatureList extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // availability: 2 = enterprise, 1 = team, 0 = personal
    this.features = [
      {
        string: "features.german",
        availability: 0
      },
      {
        string: "features.annotating",
        availability: 0
      },
      {
        string: "features.mobile_friendly",
        availability: 0
      },
      {
        string: "features.real_time",
        availability: 0
      },
      {
        string: "features.multiple_documents",
        availability: 0
      },
      {
        string: "features.pdf",
        availability: 0
      },
      {
        string: "features.branding",
        availability: 1
      },
      {
        string: "features.high_res_images",
        availability: 1
      },
      {
        string: "features.secure",
        availability: 1
      },
      {
        string: "features.image_effects",
        availability: 2
      }
    ];
  }
  render() {
    const items = this.features.map(f => (
      <FeatureListItem
        key={f.string}
        string={f.string}
        availability={f.availability}
      />
    ));

    return <div className={style.Wrap}>{items}</div>;
  }
}

function FeatureListItem({ string, availability }) {
  return (
    <div className={style.Item}>
      <div className={style.ItemName}>{getString(string)}</div>
      <div className={style.ItemAvailabilityWrap}>
        <AvailabilityIndicator availability={availability} />
      </div>
    </div>
  );
}

function AvailabilityIndicator({ availability }) {
  return (
    <div className={style.AvailabilityIndicator}>
      <div className={availability <= 0 ? style.Active : null} />
      <div className={availability <= 1 ? style.Active : null} />
      <div className={availability <= 2 ? style.Active : null} />
    </div>
  );
}

export default FullFeatureList;
