import React, { Component } from "react";
import style from "./LangSelector.module.css";
import DropIcon from "../../Icons/baseline-arrow_drop_down-24px.svg";
import { getLang, getString } from "../../Utils/Internationalization";

class LangSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { id, onClick } = this.props;

    return (
      <span id={id} className={style.Selector} onClick={onClick}>
        <span>{getString("lang." + getLang())}</span>
        <img alt="" src={DropIcon} />
      </span>
    );
  }
}

export default LangSelector;
