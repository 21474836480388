import React from "react";
import style from "./PlansLegend.module.css";
import { getString } from "../../Utils/Internationalization";

function PlansLegend() {
  return (
    <div className={style.Wrap}>
      <span className={style.Item}>
        {getString("pricing.plan.personal.title")}
        <span className={style.Dot0} />
      </span>
      <span className={style.Item}>
        {getString("pricing.plan.team.title")}
        <span className={style.Dot1} />
      </span>
      <span className={style.Item}>
        {getString("pricing.plan.enterprise.title")}
        <span className={style.Dot2} />
      </span>
    </div>
  );
}

export default PlansLegend;
