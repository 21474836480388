import React, { Component } from "react";
import { Link } from "react-router-dom";
import style from "./Footer.module.css";
import { getString } from "../../Utils/Internationalization";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={style.Wrap}>
        <div className={style.Nav}>
          <Link to="/data-protection">
            {getString("links.data_protection")}
          </Link>
          <Link to="/">{getString("links.terms_of_service")}</Link>
          <Link to="/">{getString("links.marketing_materials")}</Link>
          <a href="https://uelstudios.de">Uelstudios</a>
        </div>
      </div>
    );
  }
}

export default Footer;
