import React, { Component } from "react";
import style from "./Header.module.css";
import MenuIcon from "../../Icons/baseline-menu-24px.svg";
import MobileNavigationMenu from "../MobileNavigationMenu/MobileNavigationMenu";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import LangSelector from "../LangSelector/LangSelector";
import LangSelectDialog from "../LangSelectDialog/LangSelectDialog";
import { getString } from "../../Utils/Internationalization";
import { Switch, Route, Link as RouterLink } from "react-router-dom";
import { Link } from "react-scroll";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuVisible: false,
      langDialogVisible: false,
      blockScroll: false
    };

    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    this.toggleLangDialog = this.toggleLangDialog.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.onWindowResize.bind(this));
    this.onWindowResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize.bind(this));
  }

  onWindowResize() {
    const blockScroll = window.innerWidth < 1000;
    if (this.state.blockScroll !== blockScroll) this.setState({ blockScroll });
  }

  toggleMobileMenu() {
    this.setState({ mobileMenuVisible: !this.state.mobileMenuVisible });
  }

  toggleLangDialog() {
    this.setState({ langDialogVisible: !this.state.langDialogVisible });
  }

  componentDidUpdate() {
    const { mobileMenuVisible, langDialogVisible, blockScroll } = this.state;

    if (mobileMenuVisible || (langDialogVisible && blockScroll)) {
      disableBodyScroll();
    } else {
      clearAllBodyScrollLocks();
    }
  }

  render() {
    const { mobileMenuVisible, langDialogVisible } = this.state;

    return (
      <div className={style.Wrap}>
        <div className={style.Info}>{getString("nav.ticker")}</div>
        <div className={style.Main}>
          <div className={style.Brand}>
            <img alt="Sight" src="/branding/logo.svg" />
            <a href="/">Sight</a>
          </div>
          <div
            className={style.MobileNavigationButton}
            onClick={this.toggleMobileMenu}
          >
            <img alt="Menu" src={MenuIcon} />
          </div>
          <Switch>
            <Route
              exact
              path="/"
              component={() => (
                <NavigationHome toggleLangDialog={this.toggleLangDialog} />
              )}
            />
            <Route
              path="/"
              component={() => (
                <NavigationOther toggleLangDialog={this.toggleLangDialog} />
              )}
            />
          </Switch>
        </div>

        <MobileNavigationMenu
          visible={mobileMenuVisible}
          onClose={this.toggleMobileMenu}
          toggleLangDialog={this.toggleLangDialog}
        />

        <LangSelectDialog
          visible={langDialogVisible}
          onClose={this.toggleLangDialog}
        />
      </div>
    );
  }
}

export default Header;

function NavigationHome({ toggleLangDialog }) {
  return (
    <nav className={style.Navigation}>
      <ul>
        <li>
          <Link
            to="home"
            spy={true}
            smooth={true}
            duration={500}
            offset={0}
            activeClass={style.Selected}
          >
            {getString("nav.home")}
          </Link>
        </li>
        <li>
          <Link
            to="features"
            spy={true}
            smooth={true}
            duration={500}
            offset={0}
            activeClass={style.Selected}
          >
            {getString("nav.features")}
          </Link>
        </li>
        <li>
          <Link
            to="pricing"
            spy={true}
            smooth={true}
            duration={500}
            offset={0}
            activeClass={style.Selected}
          >
            {getString("nav.pricing")}
          </Link>
        </li>
        <li>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            duration={500}
            offset={0}
            activeClass={style.Selected}
          >
            {getString("nav.contact")}
          </Link>
        </li>
        <li>
          <LangSelector id="lang-selector-header" onClick={toggleLangDialog} />
        </li>
      </ul>
    </nav>
  );
}

function NavigationOther({ toggleLangDialog }) {
  return (
    <nav className={style.Navigation}>
      <ul>
        <li>
          <a href="/">{getString("nav.home")}</a>
        </li>
        <li>
          <a href="/#features">{getString("nav.features")}</a>
        </li>
        <li>
          <a href="/#pricing">{getString("nav.pricing")}</a>
        </li>
        <li>
          <a href="/#contact">{getString("nav.contact")}</a>
        </li>
        <li>
          <LangSelector id="lang-selector-header" onClick={toggleLangDialog} />
        </li>
      </ul>
    </nav>
  );
}
