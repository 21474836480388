import React, { Component } from "react";
import Header from "../Components/Header/Header";
import Hero from "../Components/Hero/Hero";
import ContentItem from "../Components/ContentItem/ContentItem";
import Footer from "../Components/Footer/Footer";
import FeatureList from "../Components/FeatureList/FeatureList";
import ContactInfo from "../Components/ContactInfo/ContactInfo";
import PricingList from "../Components/PricingList/PricingList";
import { getString } from "../Utils/Internationalization";
import MailingList from "../Components/MailingList/MailingList";

class PageHome extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.CONTENT = [
      {
        id: "features",
        title: getString("features.title"),
        description: getString("features.description"),
        cta: {
          name: getString("features.view_all"),
          click: () => {
            window.location = "/features";
          }
        },
        content: <FeatureList />
      },
      {
        title: getString("demo.title"),
        description: getString("demo.description"),
        cta: { name: getString("demo.online_demo"), click: console.log },
        content: <img alt="" src="/media/feature-viewer.png" />
      },
      {
        title: getString("dashboard.title"),
        description: getString("dashboard.description"),
        content: <img alt="" src="/media/feature-dashboard.png" />
      },
      {
        title: getString("scripting.title"),
        description: getString("scripting.description"),
        info: getString("scripting.info"),
        cta: { name: getString("scripting.view_docs"), click: console.log },
        content: (
          <video
            src="/media/video-placeholder.mp4"
            controls
            poster="/media/feature-script.png"
          >
            <img alt="" src="/media/feature-script.png" />
          </video>
        )
      },
      {
        id: "pricing",
        title: getString("pricing.title"),
        description: getString("pricing.description"),
        info: (
          <span>
            {getString("pricing.info")}{" "}
            <a href="/features">{getString("pricing.info.compare")}</a>
          </span>
        ),
        content: <PricingList />
      },
      {
        id: "contact",
        title: getString("contact.title"),
        description: getString("contact.description"),
        info: getString("contact.info"),
        content: <ContactInfo />
      },
      {
        title: getString("mailing.title"),
        description: getString("mailing.description"),
        info: getString("mailing.info"),
        content: <MailingList />
      }
    ];
  }

  render() {
    const contentItems = this.CONTENT.map((c, i) => (
      <ContentItem
        key={i}
        id={c.id}
        title={c.title}
        description={c.description}
        info={c.info}
        cta={c.cta}
      >
        {c.content}
      </ContentItem>
    ));

    return (
      <React.Fragment>
        <Header />
        <Hero id="home" />
        {contentItems}
        <Footer />
      </React.Fragment>
    );
  }
}

export default PageHome;
