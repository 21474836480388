import React, { Component } from "react";
import style from "./ContentItem.module.css";
import Button from "../Button/Button";
import { Element as ScrollToElement } from "react-scroll";

class ContentItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { id, title, description, cta, children, info } = this.props;

    return (
      <ScrollToElement name={id}>
        <div className={style.Wrap}>
          <div className={style.Sub}>
            <div>
              <div className={style.Title}>{title}</div>
              <p className={style.Description}>{description}</p>
            </div>
            {cta && <Button onClick={cta.click}>{cta.name}</Button>}
          </div>
          <span className={style.Content}>{children}</span>
          {info && <div className={style.Info}>{info}</div>}
        </div>
      </ScrollToElement>
    );
  }
}

export default ContentItem;
