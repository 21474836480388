import React, { Component } from "react";
import Header from "../Components/Header/Header";
import ContentItem from "../Components/ContentItem/ContentItem";
import Footer from "../Components/Footer/Footer";
import {
  getStrings,
  DEFAULT_LANGUAGE,
  getLang,
  getString,
  getFormatedString
} from "../Utils/Internationalization";

class PageTranslate extends Component {
  constructor(props) {
    super(props);
    this.state = { newStrings: {}, result: null };

    this.save = this.save.bind(this);
  }

  save() {
    this.setState({
      result: {
        from: DEFAULT_LANGUAGE,
        to: getLang(),
        strings: this.state.newStrings
      }
    });
  }

  stringUpdated(key, newValue) {
    const { newStrings } = this.state;

    newStrings[key] = newValue;

    this.setState({ newStrings });
  }

  sortObject(o) {
    const keys = Object.keys(o);
    const sorted = {};
    keys.sort().forEach(k => (sorted[k] = o[k]));
    return sorted;
  }

  render() {
    const { newStrings } = this.state;
    const strings = getStrings(DEFAULT_LANGUAGE);
    const stringKeys = Object.keys(strings);

    const to = getLang();
    const stringItems = stringKeys.map(s => (
      <LangString
        key={s}
        string={s}
        from={DEFAULT_LANGUAGE}
        to={to}
        value={newStrings[s]}
        onUpdated={value => this.stringUpdated(s, value)}
      />
    ));

    const desc = getFormatedString("translate.from_to", {
      from: getString("lang." + DEFAULT_LANGUAGE),
      to: getString("lang." + to)
    });

    const { result } = this.state;
    if (result)
      return (
        <div style={{ backgroundColor: "#efefef" }}>
          <center>
            <button onClick={() => this.setState({ result: null })}>
              &nbsp;&nbsp;&nbsp;X&nbsp;&nbsp;&nbsp;
            </button>
          </center>
          <hr />
          <h2>Changes</h2>
          <textarea
            value={JSON.stringify(result)}
            readOnly
            style={{ width: "100vw", height: "25vh", fontSize: 18 }}
          />
          <hr />
          <h2>Partial Merge</h2>
          <textarea
            value={JSON.stringify(
              this.sortObject({
                ...getStrings(to),
                ...result.strings
              })
            )}
            readOnly
            style={{ width: "100vw", height: "25vh", fontSize: 18 }}
          />
          <hr />
          <h2>Full Merge</h2>
          <textarea
            value={JSON.stringify(
              this.sortObject({
                ...getStrings(DEFAULT_LANGUAGE),
                ...getStrings(to),
                ...result.strings
              })
            )}
            readOnly
            style={{ width: "100vw", height: "25vh", fontSize: 18 }}
          />
        </div>
      );

    return (
      <React.Fragment>
        <Header />
        <ContentItem
          title={getString("translate.title")}
          description={desc}
          info={getString("translate.info")}
          cta={{ name: getString("translate.save"), click: this.save }}
        >
          <div style={{ display: "flex", fontSize: 18, color: "blue" }}>
            <small style={{ flex: 1, fontSize: 15 }}>
              {getString("translate.key")}
            </small>
            <span style={{ flex: 2, font: "inherit", border: "none" }}>
              {getString("lang." + DEFAULT_LANGUAGE)}
            </span>
            <small>&nbsp;&nbsp;</small>
            <span style={{ flex: 2 }}>{getString("lang." + to)}</span>
          </div>
          <hr style={{ margin: "16px 0", borderColor: "blue" }} />
          {stringItems}
        </ContentItem>
        <Footer />
      </React.Fragment>
    );
  }
}

function LangString({ string, from, to, value, onUpdated }) {
  const fill = getString("translate.no_value");
  const fromMsg = getString(string, from);
  const toMsg = value || getString(string, to, fill);

  const isNew = fill === toMsg && string !== "translate.no_value";
  const diff = value;

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          fontSize: 18,
          color: isNew ? "red" : "#000"
        }}
      >
        <small
          style={{
            flex: 1,
            fontSize: 15,
            fontWeight: diff ? "bold" : "normal"
          }}
        >
          {string}
        </small>
        <textarea
          style={{ flex: 2, font: "inherit", border: "none" }}
          readOnly
          value={fromMsg}
        />
        <small>&nbsp;&nbsp;</small>
        <textarea
          style={{ flex: 2, font: "inherit" }}
          defaultValue={toMsg}
          onBlur={event => onUpdated(event.target.value)}
        />
      </div>
      <hr style={{ margin: "16px 0", borderColor: isNew ? "red" : "#000" }} />
    </React.Fragment>
  );
}

export default PageTranslate;
