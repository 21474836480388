import React, { Component } from "react";
import style from "./MailingList.module.css";
import Button from "../Button/Button";
import { getString } from "../../Utils/Internationalization";

class MailingList extends Component {
  constructor(props) {
    super(props);
    this.state = { completed: false, error: "", value: "" };

    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  onChange(event) {
    this.setState({ value: event.target.value });
  }

  submit() {
    const { value } = this.state;
    let error = null;

    if (!value.includes("@")) error = "mailing.error.invalid";
    if (!value.includes(".")) error = "mailing.error.invalid";

    if (error) this.setState({ error });
    else {
      // TODO: Send email (value) to server
      // fetch("", { method: "POST", body: JSON.stringify(body) });
      this.setState({ completed: true, error: null });
    }
  }

  render() {
    const { completed, error } = this.state;

    return (
      <div className={style.OuterWrap}>
        {completed ? (
          <p className={style.SuccessMessage}>
            {getString("mailing.thankyou")}
          </p>
        ) : (
          <div className={style.Wrap}>
            <input
              className={style.Input}
              placeholder={getString("mailing.placeholder")}
              onChange={this.onChange}
            />
            <Button onClick={this.submit}>{getString("mailing.submit")}</Button>
          </div>
        )}

        {error && <p className={style.ErrorMessage}>{getString(error)}</p>}
      </div>
    );
  }
}

export default MailingList;
