import React, { Component } from "react";
import style from "./Hero.module.css";
import Button from "../Button/Button";
import { getString } from "../../Utils/Internationalization";

class Hero extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { id } = this.props;

    return (
      <div className={style.Hero} id={id}>
        <div className={style.Item}>
          <div className={style.Parts}>
            <div className={style.Title}>{getString("hero.title")}</div>
            <p className={style.Description}>{getString("hero.description")}</p>
            <Button>{getString("hero.online_demo")}</Button>
          </div>
          <div className={style.Parts}>
            <img alt="" src="/media/viewer-mock.png" />
          </div>
        </div>
      </div>
    );
  }
}

export default Hero;
