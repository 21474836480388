/**
 * @summary Provides functionality for internationalization. Languages are retrieved from "/src/Lang/*.json"
 * @author Paul von Allwörden
 */

const languageCodes = require("../Lang/index.json");

const KEY_LANG = "lang";
const DEFAULT_LANGUAGE = "en";

// Set language code (en / de / fr)
function setLang(code) {
  localStorage.setItem(KEY_LANG, code);
}

// Set language code from browser
function setInitialLang() {
  let code = DEFAULT_LANGUAGE;

  try {
    const userLang = navigator.language || navigator.userLanguage;
    const parts = userLang.split("-");
    code = parts[0];
  } catch (e) {
    console.error(e);
  }

  // Is the language available?
  if (isValidCode(code)) setLang(code);
  else setLang(DEFAULT_LANGUAGE);
}

// Is the code a valid lang code that is supported?
function isValidCode(code) {
  return languageCodes.includes(code);
}

// Get language code (de / en / fr)
function getLang() {
  let lang = localStorage.getItem(KEY_LANG);
  if (!lang) {
    setInitialLang();
    return localStorage.getItem(KEY_LANG);
  } else return lang;
}

function getLangCodes() {
  return languageCodes;
}

// Get all strings for a given language code
function getStrings(code) {
  if (isValidCode(code)) return require(`../Lang/${code}.json`);
  else return getStrings(DEFAULT_LANGUAGE);
}

// Get a localized string
// Returns string from DEFAULT_LANG if id is not available in selected language.
function getString(id, langCode, fill) {
  const lang = langCode || getLang();
  const strings = getStrings(lang);

  let msg = strings[id];

  if (msg) return msg;
  return fill || getStrings(DEFAULT_LANGUAGE)[id] || lang + "." + id;
}

// Get a localized string with filled values. e.g. "Hello %NAME%" -> values: {name: "Peter"} -> "Hello Peter".
// Returns string from DEFAULT_LANG if id is not available in selected language.
function getFormatedString(id, values, langCode, fill) {
  let msg = getString(id, langCode, fill);

  const valKeys = Object.keys(values);
  valKeys.forEach(key => {
    msg = msg.replace(`%${key.toUpperCase()}%`, values[key]);
  });

  return msg;
}

export {
  getString,
  getFormatedString,
  getLang,
  setLang,
  getLangCodes,
  getStrings,
  DEFAULT_LANGUAGE
};
