import React, { Component } from "react";
import style from "./PricingList.module.css";
import Button from "../Button/Button";
import { getString, getFormatedString } from "../../Utils/Internationalization";
import { scroller } from "react-scroll";

class PricingList extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.selectContact = this.selectContact.bind(this);
  }

  selectContact() {
    scroller.scrollTo("contact", {
      duration: 500,
      smooth: true
    });
  }

  render() {
    const features_1 = [
      ["User Profiles", "1"],
      ["Approvals / Month", "10"],
      ["Files / Month", "20"],
      ["Max File Size", "200 MB"],
      ["Image Effects", "NO"],
      ["Support", "Email"]
    ];

    const features_2 = [
      ["User Profiles", "8"],
      ["Approvals / Month", "50"],
      ["Files / Month", "100"],
      ["Max File Size", "500 MB"],
      ["Image Effects", "YES"],
      ["Custom Branding", "YES"],
      ["Support", "Telephone"]
    ];

    const features_3 = [
      ["User Profiles", "Unlimited"],
      ["Approvals / Month", "Unlimited"],
      ["Files / Month", "Unlimited"],
      ["Max File Size", "1 GB"],
      ["Sight Scripting", "YES"],
      ["Support", "Telephone 24 / 7"]
    ];

    return (
      <div className={style.Wrap}>
        <PricingItem
          title={getString("pricing.plan.personal.title")}
          price={getFormatedString("pricing.plan.price_per_month", {
            price: "35€"
          })}
          features={features_1}
          btnText={getString("pricing.plan.contact")}
          onSelect={this.selectContact}
        />
        <PricingItem
          title={getString("pricing.plan.team.title")}
          price={getFormatedString("pricing.plan.price_per_month", {
            price: "60€"
          })}
          perUser={true}
          features={features_2}
          btnText={getString("pricing.plan.contact")}
          onSelect={this.selectContact}
        />
        <PricingItem
          title={getString("pricing.plan.enterprise.title")}
          price={getString("pricing.plan.price_custom")}
          features={features_3}
          btnText={getString("pricing.plan.contact")}
          onSelect={this.selectContact}
        />
      </div>
    );
  }
}

function PricingItem({ title, price, perUser, features, btnText, onSelect }) {
  const featItms = features.map(f => (
    <span key={f[0]}>
      <span>{f[0]}</span>
      <span>{f[1]}</span>
    </span>
  ));

  return (
    <div className={style.Item}>
      <div className={style.Head}>{title}</div>
      <p className={style.Price}>{price}</p>
      {perUser && (
        <p className={style.PerUser}>{getString("pricing.plan.per_user")}</p>
      )}
      <div className={style.Features}>{featItms}</div>
      <Button onClick={onSelect}>{btnText}</Button>
    </div>
  );
}

export default PricingList;
