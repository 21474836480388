import React, { Component } from "react";
import style from "./ContactInfo.module.css";
import { getString, getFormatedString } from "../../Utils/Internationalization";

class ContactInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const businessHours = getFormatedString("contact.business_hours", {
      from_day: "Mo",
      to_day: "Fr",
      from_time: "7:00",
      to_time: "18:00"
    });

    return (
      <div className={style.Wrap}>
        <div className={style.Item}>
          <p className={style.Title}>{getString("contact.telephone")}</p>
          <p className={style.Info}>
            +49 04191 / 91 90 40
            <br />
            {businessHours}
          </p>
        </div>
        <div className={style.Item}>
          <p className={style.Title}>{getString("contact.email")}</p>
          <p className={style.Info}>uelstudios@gmail.com</p>
        </div>
        <div className={style.Item}>
          <p className={style.Title}>{getString("contact.address")}</p>
          <p className={style.Info}>
            Tanneck 4
            <br />
            24568 Oersdorf
            <br />
            Germany
          </p>
        </div>
      </div>
    );
  }
}

export default ContactInfo;
