import React, { Component } from "react";
import style from "./Button.module.css";

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { children, onClick } = this.props;
    return (
      <button className={style.Button} onClick={onClick}>
        {children}
      </button>
    );
  }
}

export default Button;
