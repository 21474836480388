import React, { Component } from "react";
import style from "./MobileNavigationMenu.module.css";
import LangSelector from "../LangSelector/LangSelector";
import { getString } from "../../Utils/Internationalization";

class MobileNavigationMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    if (event.target === event.currentTarget) this.props.onClose();
  }

  render() {
    const { visible, onClose, toggleLangDialog } = this.props;
    const wrapClass = visible ? style.Wrap : style.WrapHidden;

    return (
      <div className={wrapClass} onClick={this.onClick}>
        <nav className={style.Navigation}>
          <ul>
            <li>
              <a href="/#home" onClick={onClose}>
                {getString("nav.home")}
              </a>
            </li>
            <li>
              <a href="/#features" onClick={onClose}>
                {getString("nav.features")}
              </a>
            </li>
            <li>
              <a href="/#pricing" onClick={onClose}>
                {getString("nav.pricing")}
              </a>
            </li>
            <li>
              <a href="/#contact" onClick={onClose}>
                {getString("nav.contact")}
              </a>
            </li>
            <li>
              <LangSelector onClick={toggleLangDialog} />
            </li>
          </ul>
          <button className={style.CloseBtn} onClick={onClose}>
            {getString("nav.close")}
          </button>
        </nav>
      </div>
    );
  }
}

export default MobileNavigationMenu;
