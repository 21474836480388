import React, { Component } from "react";
import style from "./LangSelectDialog.module.css";
import {
  getLangCodes,
  getString,
  setLang
} from "../../Utils/Internationalization";

class LangSelectDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { pos: 0 };

    this.onWrapClick = this.onWrapClick.bind(this);
  }

  selectLanguage(code) {
    setLang(code);

    // Reload
    window.location.reload();

    this.props.onClose();
  }

  componentDidMount() {
    window.addEventListener("resize", this.calcPosition.bind(this));
    this.calcPosition();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calcPosition.bind(this));
  }

  calcPosition() {
    const pos = document.getElementById("lang-selector-header").offsetLeft;
    this.setState({ pos });
  }

  onWrapClick(event) {
    if (event.currentTarget === event.target) {
      this.props.onClose();
    }
  }

  render() {
    const { pos } = this.state;
    const { visible, onClose } = this.props;
    const wrapClass = visible ? style.Wrap : style.WrapHidden;

    const langItems = getLangCodes().map(lang => (
      <div
        key={lang}
        className={style.Item}
        onClick={() => this.selectLanguage(lang)}
      >
        {getString("lang." + lang, lang)}
      </div>
    ));

    return (
      <div className={wrapClass} onClick={this.onWrapClick}>
        <div className={style.Panel} style={{ left: pos }}>
          <div className={style.Title}>Language</div>
          {langItems}
          <hr />
          <div className={style.ItemClose} onClick={onClose}>
            Cancel
          </div>
        </div>
      </div>
    );
  }
}

export default LangSelectDialog;
