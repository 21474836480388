import React, { Component } from "react";
import Header from "../Components/Header/Header";
import ContentItem from "../Components/ContentItem/ContentItem";
import Footer from "../Components/Footer/Footer";
import FullFeatureList from "../Components/FullFeatureList/FullFeatureList";
import PricingList from "../Components/PricingList/PricingList";
import { getString } from "../Utils/Internationalization";
import ContactInfo from "../Components/ContactInfo/ContactInfo";
import MailingList from "../Components/MailingList/MailingList";
import PlansLegend from "../Components/PlansLegend/PlansLegend";

class PageFeatures extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <ContentItem
          title={getString("features_page.title")}
          description={getString("features_page.description")}
          info={<PlansLegend />}
        >
          <FullFeatureList />
        </ContentItem>
        <ContentItem
          title={getString("pricing.title")}
          description={getString("pricing.description")}
        >
          <PricingList />
        </ContentItem>
        <ContentItem
          title={getString("contact.title")}
          description={getString("contact.description")}
          info={getString("contact.info")}
        >
          <ContactInfo />
        </ContentItem>
        <ContentItem
          title={getString("mailing.title")}
          description={getString("mailing.description")}
          info={getString("mailing.info")}
        >
          <MailingList />
        </ContentItem>
        <Footer />
      </React.Fragment>
    );
  }
}

export default PageFeatures;
