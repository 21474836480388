import React, { Component } from "react";
import style from "./FeatureList.module.css";
import { getString } from "../../Utils/Internationalization";

class FeatureList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const features = [
      {
        string: "features.annotating",
        details: "features.annotating.details",
        src: "/media/feat_marker.png"
      },
      {
        string: "features.high_res_images",
        details: "features.high_res_images.details",
        src: "/media/feat_highres.png"
      },
      {
        string: "features.mobile_friendly",
        details: "features.mobile_friendly.details",
        src: "/media/feat_mobile.png"
      },
      {
        string: "features.real_time",
        details: "features.real_time.details",
        src: "/media/feat_realtime.png"
      },
      {
        string: "features.multiple_documents",
        details: "features.multiple_documents.details",
        src: "/media/feat_multi_doc.png"
      },
      {
        string: "features.pdf",
        details: "features.pdf.details",
        src: "/media/feat_pdf.png"
      },
      {
        string: "features.german",
        details: "features.german.details",
        src: "/media/feat_german.png"
      },
      {
        string: "features.branding",
        details: "features.branding.details",
        src: "/media/feat_custom_branding.png"
      },
      {
        string: "features.image_effects",
        details: "features.image_effects.details",
        src: "/media/feat_image_effects.png"
      }
    ];

    const featItems = features.map(f => (
      <FeatureItem
        key={f.string}
        name={getString(f.string)}
        details={getString(f.details)}
        src={f.src}
      />
    ));

    return <div className={style.Wrap}>{featItems}</div>;
  }
}

function FeatureItem({ name, details, src }) {
  return (
    <div className={style.Item}>
      <div className={style.ItemInnerWrap}>
        <img alt="" src={src} />
        <div className={style.Details}>{details}</div>
      </div>
      <p>{name}</p>
    </div>
  );
}

export default FeatureList;
