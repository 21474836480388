import React, { Component } from "react";
import Header from "../Components/Header/Header";
import ContentItem from "../Components/ContentItem/ContentItem";
import Footer from "../Components/Footer/Footer";

class PageDataProtection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <ContentItem title="Legal">
          <p>
            Paul von Allwörden
            <br />
            Tanneneck 4<br />
            24568 Oersdorf
            <br />
            Vertreten durch:
            <br />
            Paul von Allwörden
            <br />
            Kontakt:
            <br />
            Telefon: +49-1517 010 3969
            <br />
            E-Mail: P_allwoerden@web.de
            <br />
            <br />
            <br />
            Haftungsausschluss:
            <br />
            <br />
            Haftung für Links
            <br />
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
            Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
            nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
          </p>
        </ContentItem>
        <Footer />
      </React.Fragment>
    );
  }
}

export default PageDataProtection;
