import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PageHome from "./Pages/PageHome";
import PageLegal from "./Pages/PageLegal";
import PageTranslate from "./Pages/PageTranslate";
import PageFeatures from "./Pages/PageFeatures";

function App() {
  return (
    <Switch>
      <Route exact path="/" component={PageHome} />
      <Route exact path="/data-protection" component={PageLegal} />
      <Route exact path="/features" component={PageFeatures} />
      <Route exact path="/translate" component={PageTranslate} />
      <Redirect from="/" to="/" />
    </Switch>
  );
}

export default App;
